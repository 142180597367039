"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rankings = [
    {
        id: 1,
        appId: 8,
        title: "Ránkings de la aplicación iMossos",
        description: "En iMossos, nuestros usuarios compiten cada día para demostrar quién es el mejor. Aquí puedes consultar los rankings de quienes han completado más tests, obtenido las puntuaciones más altas y dominado nuestras pruebas. Los rankings semanales se actualizan cada hora, los mensuales cada 12 horas y los anuales diariamente. ¡Las posiciones están en constante cambio, y el primer lugar podría ser tuyo!",
        link: "/rankings/imossos",
        enabled: true,
        image: "/images/rankings/imossos.jpg",
        message: "¿Estás entre los mejores de la semana? 🎉 Escríbenos a {{email}} y reclama tu premio.",
    },
    {
        id: 2,
        appId: 1,
        title: "Ránkings de la aplicación iGuB",
        description: "En iGUB, nuestros participantes compiten cada día para destacar y demostrar quién es el mejor. Aquí puedes consultar los rankings de los usuarios que han completado más tests, alcanzado las puntuaciones más altas y superado nuestros desafíos. Los rankings semanales se actualizan cada hora, los mensuales cada 12 horas y los anuales diariamente. ¡Las posiciones cambian constantemente, y tú podrías ser quien llegue a la cima!",
        link: "/rankings/igub",
        enabled: true,
        image: "/images/rankings/igub.jpg",
    },
    {
        id: 3,
        appId: 15,
        title: "Ránkings de la aplicación iPoliciaMadrid",
        description: "En iPolicíaMadrid, nuestros usuarios se esfuerzan cada día por destacar y demostrar su excelencia. Aquí puedes explorar los rankings de quienes han completado más tests, obtenido las mejores puntuaciones y superado nuestros retos. Los rankings semanales se actualizan cada hora, los mensuales cada 12 horas y los anuales una vez al día. ¡Las posiciones están en constante cambio, y tú podrías ser el próximo en liderar la tabla!",
        link: "/rankings/ipoliciamadrid",
        enabled: true,
        image: "/images/rankings/ipmm.jpg",
    },
    {
        id: 3,
        appId: 14,
        title: "Ránkings de la aplicación iSanitarios",
        description: "",
        link: "/rankings/isanitarios",
        enabled: true,
        image: "/images/rankings/isanitarios.jpg",
    },
    {
        id: 4,
        appId: 4,
        title: "Ránkings de la aplicación iPoL",
        description: "",
        link: "/rankings/ipol",
        enabled: false,
        image: "/images/rankings/ipol.jpg",
    },
    {
        id: 5,
        appId: 3,
        title: "Ránkings de la aplicación iCorreos",
        description: "",
        link: "/rankings/icorreos",
        enabled: false,
        image: "/images/rankings/icorreos.jpg",
    },
    {
        id: 6,
        appId: 5,
        title: "Ránkings de la aplicación iGC",
        description: "",
        link: "/rankings/igc",
        enabled: false,
        image: "/images/rankings/igc.jpg",
    },
    {
        id: 7,
        appId: 2,
        title: "Ránkings de la aplicación iCNP",
        description: "",
        link: "/rankings/icnp",
        enabled: false,
        image: "/images/rankings/icnp.jpg",
    },
    {
        id: 8,
        appId: 10,
        title: "Ránkings de la aplicación iBarcelona",
        description: "",
        link: "/rankings/ibarcelona",
        enabled: false,
        image: "/images/rankings/ibarcelona.jpg",
    },
];
exports.default = rankings;
